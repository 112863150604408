import { Search } from '@/components/pages/Search'
import SEO_PAGES from '@/constants/seo-pages'
import { withContext } from '@/lib/server/context'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

// Generate a random seed to use for sort=random
const genSeed = () => Math.floor(Math.random() * 10000)

export const getServerSideProps = withContext(
  async ({ locale, query }, { api, engineId }) => {
    const searchPage = await api.getPage(engineId, SEO_PAGES.search)
    const settings = searchPage.settings
    const sort = query.sort ?? settings.searchSortkey
    const sortSeed = sort === 'random' ? genSeed() : null

    const [homePage, allProperties, properties] = await Promise.all([
      api.getPage(engineId, SEO_PAGES.home),
      api.getAllProperties(engineId),
      api.searchProperties(engineId, {
        page_size: query.page_size,
        sort: sort,
        sort_seed: sortSeed
      })
    ])

    return {
      props: {
        ...(await serverSideTranslations(locale, ['search', 'common'])),
        allProperties,
        properties,
        page: {
          ...searchPage,
          seo: homePage.seo
        },
        settings,
        engineId,
        sort,
        sortSeed
      }
    }
  }
)

export default Search
